// rails-ujs
import Rails from 'rails-ujs';
Rails.start();

import WebpackerReact from 'webpacker-react'

// アラート
import Alert from 'products/components/alert'
WebpackerReact.setup( { Alert } )

// 体験イベントに参加
import Entry from 'products/components/entry'
WebpackerReact.setup( { Entry } )

// お問い合わせ
import Contact from 'products/components/contact'
WebpackerReact.setup( { Contact } )

// 遅延読み込み
import LazyLoadVideo from 'products/components/lazy_load_video'
WebpackerReact.setup( { LazyLoadVideo } )

// franchise お問い合わせ
import FranchiseInquiry from 'products/components/franchise_inquiry'
WebpackerReact.setup( { FranchiseInquiry } )

// franchise 資料ダウンロード
import FranchiseDownload from 'products/components/franchise_download'
WebpackerReact.setup( { FranchiseDownload } )

// Redux Sample
import Sample from 'products/components/sample'
WebpackerReact.setup( { Sample } )

// 動画自動再生
import VideoAutoplay from 'products/components/video_autoplay'
WebpackerReact.setup( { VideoAutoplay } )

// トップに戻るボタン
import TopButton from 'products/components/top_button'
WebpackerReact.setup( { TopButton } )

// 追従パーツ
import FollowBanner from 'products/components/follow_banner'
WebpackerReact.setup( { FollowBanner } )

// 上からヘッダーが降りてくる
import 'products/scripts/fixed_header.es6'

// SPのメニュー開く
import 'products/scripts/drawer.es6'

// ページ内スクロール
import 'products/scripts/scroll.es6'

// ページ遷移後スクロール
import 'products/scripts/transited_scroll.es6'
