import { combineReducers } from 'redux';
import { INIT_FORM, INIT_EVENT, PUSH_PLACE, PUSH_COURSE, PUSH_PERIOD, VALIDATE } from './action.es6';

const form = (state = {
  token:    null,
  defaults: {},
  device:   null,
}, action) => {
  switch (action.type) {
    case INIT_FORM:
      return {
        ...state,
        ...action.props
      };
    break;

    default:
      return state;
  }
};

const event = (state = {
  master:  {},
  choices: {},
  inputs:  {},
}, action) => {

  const generateChoices = (master, inputs = {}) => {
    let courses, periods;

    try {
      courses = Object.keys(master.events[inputs.place]).map((code) => ({ code, name: master.courses[code] }));
    } catch (error) {
      courses = null;
    }

    try {
      periods = master.events[inputs.place][inputs.course];
    } catch (error) {
      periods = null;
    }

    return {
      places: master.places,
      courses,
      periods,
    }
  };

  switch (action.type) {
    case INIT_EVENT:
      {
        // マスターデータ
        const { master, inputs } = action.props;
        // 教室一覧
        const places = master.places;
        // 返却
        return {
          ...state,
          ...{ master },
          choices: generateChoices(master, inputs),
          inputs
        };
      }
    break;

    case PUSH_PLACE:
      {
        // マスターデータ
        const { master } = state;

        const inputs = {
          ...state.inputs,
          place: action.code,
          course: null,
          period: null,
        }

        // 返却
        return {
          ...state,
          choices: generateChoices(master, inputs),
          inputs,
        };
      }
    break;

    case PUSH_COURSE:
      {
        // マスターデータ
        const { master, inputs } = state;
        // 開催日時一覧
        const periods = master.events[inputs.place][action.code];

        // 返却
        return {
          ...state,
          choices: {
            ...state.choices,
            periods
          },
          inputs: {
            ...state.inputs,
            course: action.code,
            period: null,
          }
        };
      }
    break;

    case PUSH_PERIOD:
      {
        // 返却
        return {
          ...state,
          inputs: {
            ...state.inputs,
            period: action.id,
          }
        };
      }
    break;

    default:
      return state;
  }
};


const error = (state = {
  messages: {},
  valid: null,
}, action) => {
  switch (action.type) {
    case VALIDATE:
      return { ...action.params };
    break;

    default:
      return state;
  }
};


export default combineReducers( { form, event, error } );
