import $ from 'jquery';

// ドロワーメニュー開く
window.addEventListener('load', () => {
  const body = document.body;
  const $trigger = $('.js-drawerOpen');

  $trigger.on('click', function () {
    body.dataset.menuOpen = body.dataset.menuOpen == 'true'? 'false' : 'true';
  });
}, false);
