// import modules
import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'

// import action
import { dropAlert } from './action.es6'

/**
 *  Container Class
 *  @version 2016/05/02
 *  @author ryo.aso
 */
class Container extends Component {
  /**
   *  通知ウィンドウを閉じる
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  _execClose() {
    this.props.dispatch(dropAlert());
  }

  /**
   *  親要素のクリックイベントを引き継がない
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  _stopPropagation(event) {
    event.stopPropagation();
  }

  /**
   *  表示処理
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  render () {
    const { alerts } = this.props;

    if (!alerts.items.length) return null;

    return (
      <div className='c-modal'>
        <div className='c-modal__overlay' onClick={::this._execClose}>
          <div className='c-modal__wrap' onClick={this._stopPropagation}>
            <div className='c-modal__title'>{alerts.items[0].title}</div>
            <div className='c-modal__message' dangerouslySetInnerHTML={{__html: alerts.items[0].message}}></div>
            <nav>
              <span onClick={::this._execClose} className='c-btnMain-standard c-btnSize-50 c-modal__button c-modal__button-close'>閉じる</span>
            </nav>
            <div onClick={::this._execClose} className='c-modal__close-icon'>×</div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * State to Props
 * @const {object}
 */
const mapStateToProps = state => {
  return state;
}

export default connect(mapStateToProps)(Container);
