import validator  from 'validator'; // @see https://www.npmjs.com/package/validator

/**
 *  Method Name
 *  @const {object}
 */
export const INITIALIZE = 'INITIALIZE';

/**
 *  Method Name
 *  @const {object}
 */
export const VALIDATE = 'VALIDATE';

/**
 *  Action Method
 *  @const {object}
 *  @version 2016/05/02
 *  @author ryo.aso
 */
export const initialize = (props) => {
  return {
    type: INITIALIZE,
    props
  };
};

/**
 *  Action Method
 *  @const {object}
 *  @version 2016/05/02
 *  @author ryo.aso
 */
export const validate = (inputs) => {
  const { contact } = inputs;

  // エラーメッセージ
  const messages = {
    // 名前
    name: (validator.isLength(contact['name'].trim(), {min: 2, max: 255})) ? null : '名前を入力してください',
    // メールアドレス
    email: (validator.isEmail(contact['email'].trim())) ? null : 'メールアドレスを入力してください',
    // お問い合わせ内容
    content: (validator.isLength(contact['content'].trim(), {min: 2, max: 60000})) ? null : 'お問い合わせ内容を入力してください',
    // チェックボックス
    check: (validator.contains(inputs['check'], 'on')) ? null : '同意してください',
  };

  const valid = Object.keys(messages).every( (key) => ( messages[key] === null ) );

  return {
    type: VALIDATE,
    params: { messages, valid },
  };
};
