import React, { useState } from 'react';
import WebpackerReact from 'webpacker-react';
import AuthenciticyToken from 'components/AuthenticityToken.js';

const FranchiseDownload = (props) => {
  const desired_openings = props.desired_openings
  const [validationResults, setValidationResults] = useState({ company_name: null, name: null, tel: null, email: null, privacy: null });
  const telRegexp = /^0\d{9,10}$/;

  const checkCompanyName = (e) => {
    const isCompanyNameValid = e.target.value !== ''; 
    setValidationResults((companyErrors) => ({ ...companyErrors, company_name: isCompanyNameValid }));
  }

  const checkName = (e) => {
    const isNameValid = e.target.value !== ''; 
    setValidationResults((nameErrors) => ({ ...nameErrors, name: isNameValid }));
  }

  const checkTel = (e) => {
    const isTelValid = telRegexp.test(e.target.value);
    setValidationResults((telErrors) => ({ ...telErrors, tel: isTelValid }));
  }


  const checkEmail = (e) => {
    const isEmailValid = e.target.value.match(/.+@.+\..+/) !== null; 
    setValidationResults((emailErrors) => ({ ...emailErrors, email: isEmailValid }));
  }

  const checkPrivacy = () => {
    if (validationResults.privacy === true) {
      setValidationResults({ ...validationResults, privacy: false });
    } else {
      setValidationResults({ ...validationResults, privacy: true });
    }
  }

  return (
    <div>
      <form className='p-form-form' method='post'>
        <AuthenciticyToken />
        <div className='p-form-form__field'>
          <label className={`p-form-form__label ${validationResults.company_name === false? 'p-form-form__error' : '' }`}>
            会社名
            <span className='p-form-form__required'>★</span>
          </label>
          <input
              className={`${validationResults.company_name === false? 'p-form-form__textError' : 'p-form-form__text' }`}
              name='contact[company_name]'
              placeholder='コードキャンプ株式会社'
              type='text'
              maxLength='255'
              onChange={checkCompanyName}
            />
          <p className='p-form-form__supplement'>※個人の方は個人とご入力ください</p>
        </div>
        <div className='p-form-form__field'>
          <label className={`p-form-form__label ${validationResults.name === false? 'p-form-form__error' : '' }`}>
            お名前
            <span className='p-form-form__required'>★</span>
          </label>
          <input
              className={`${validationResults.name === false? 'p-form-form__textError' : 'p-form-form__text' }`}
              name='contact[name]'
              placeholder='コード 太郎'
              type='text'
              maxLength='255'
              onChange={checkName}
            />
        </div>
        <div className='p-form-form__field'>
          <label className={`p-form-form__label ${validationResults.tel === false? 'p-form-form__error' : '' }`}>
            電話番号
            <span className='p-form-form__required'>★</span>
          </label>
          <input
              className={`${validationResults.tel === false? 'p-form-form__textError' : 'p-form-form__text' }`}
              name='contact[tel]'
              placeholder='00000000000'
              type='tel'
              onChange={checkTel}
            />
        </div>
        <div className='p-form-form__field'>
          <label className={`p-form-form__label ${validationResults.email === false? 'p-form-form__error' : '' }`}>
            メールアドレス
            <span className='p-form-form__required'>★</span>
          </label>
          <input
              className={`${validationResults.email === false? 'p-form-form__textError' : 'p-form-form__text' }`}
              name='contact[email]'
              placeholder='mail@example.com'
              type='email'
              maxLength='255'
              onChange={checkEmail}
            />
        </div>
        <div className='p-form-form__field--select'>
            <p className='p-form-form__label'>希望開校時期</p>
              <select name='contact[desired_opening]' className='p-form-form__select'>
                <option value='' className='p-form-form__select--item'></option>
                {desired_openings.map(([label, value]) => {
                  return (
                    <option name='contact[desired_opening]' key={value} value={value} className='p-form-form__select--item'>{label}</option>
                  );
                })}
              </select>
          </div>
        <div className='p-form-form__field'>
          <label className='p-form-form__label'>開校希望エリア</label>
          <input
              className='p-form-form__text'
              name='contact[desired_area]'
              placeholder='東京都　品川区'
              type='text'
              maxLength='255'
            />
          <p className='p-form-form__supplement'>※市区町村までご記入ください。</p>
        </div>
        <div className='p-form-form__field--privacyPolicy'>
            <label className='p-form-form__checkboxLabel'>
              <input className='p-form-form__checkbox' name='check' type='checkbox' value='on' onChange={checkPrivacy}/>
              <i className={`${validationResults.privacy === false? 'p-form-form__checkboxIconError' : 'p-form-form__checkboxIcon' }`}></i>
            </label>
            <p className='p-form-form__privacyPolicy'><a href='/privacy' target='_blank' className='p-form-form__link'>プライバシーポリシー</a>に同意します</p>
            { validationResults.privacy === false && <div className='p-form-form__policyError'></div> }
        </div>
        <div className='p-form-form__field--button'>
          <input type='submit' className={`${Object.values(validationResults).every(Boolean) ? 'p-form-form__button': 'p-form-form__buttonOff' }`} value='送信する'/>
        </div>
      </form>
    </div>
  );
};

export default WebpackerReact.setup({ FranchiseDownload });
