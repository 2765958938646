import { combineReducers } from 'redux';
import { INITIALIZE, VALIDATE } from './action.es6';

const form = (state = {
  token: null,
}, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        token: action.props.token,
      };
    break;

    default:
      return state;
  }
};


const error = (state = {
  messages: {},
  valid: null,
}, action) => {
  switch (action.type) {
    case VALIDATE:
      return { ...action.params };
    break;

    default:
      return state;
  }
};


export default combineReducers( { form, error } );
