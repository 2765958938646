import $ from 'jquery';

// ページ内リンクをクリックするとスクロール
window.addEventListener('load', () => {
  const OFFSET = 60;
  const SPEED = 500;

  $("a[href^='#']").on('click', function () {

    const href = $(this).attr('href');
    const position = $(href).offset().top - OFFSET;

    $('html, body').animate({scrollTop:position}, SPEED, 'swing');
    return false;
  });
}, false);
