import React, { useState, useEffect } from 'react';

const TopButton = (props) => {
  const device = props.device;
  const [isReached, setIsReached] = useState(false)
  const buttonText = device === 'pc' ? 'top' : '';
  const onScroll = () => {
    const topPosition = device === 'sp' ? window.innerHeight - 400 : 400;
    const scrollPosition = window.pageYOffset;

    if (scrollPosition >= topPosition) {
      setIsReached(true);
    } else {
      setIsReached(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  });
  return (
    <a href='#school-top' className={`${isReached ? 'c-topButton' : 'c-topButtonDisabled' }`}>
      <div className='c-topButton__link'></div>
      {
        device === 'pc' ? <p className='c-topButton__text'>top</p>: null}
    </a>
  );
}
export default TopButton;