import validator  from 'validator'; // @see https://www.npmjs.com/package/validator

/**
 *  Method Name
 *  @const {object}
 */
export const INIT_FORM = 'INIT_FORM';

/**
 *  Method Name
 *  @const {object}
 */
export const INIT_EVENT = 'INIT_EVENT';

/**
 *  Method Name
 *  @const {object}
 */
export const VALIDATE = 'VALIDATE';

/**
 *  Method Name
 *  @const {object}
 */
export const PUSH_PLACE = 'PUSH_PLACE';

/**
 *  Method Name
 *  @const {object}
 */
export const PUSH_COURSE = 'PUSH_COURSE';

/**
 *  Method Name
 *  @const {object}
 */
export const PUSH_PERIOD = 'PUSH_PERIOD';

/**
 *  Action Method
 *  @const {object}
 *  @version 2016/05/02
 *  @author ryo.aso
 */
export const initForm = (props) => ({
  type: INIT_FORM,
  props
});

/**
 *  Action Method
 *  @const {object}
 *  @version 2016/05/02
 *  @author ryo.aso
 */
export const initEvent = (props) => ({
  type: INIT_EVENT,
  props
});

/**
 *  Action Method
 *  @const {object}
 *  @version 2016/05/02
 *  @author ryo.aso
 */
export const pushPlace = (code) => ({
  type: PUSH_PLACE,
  code
});

/**
 *  Action Method
 *  @const {object}
 *  @version 2016/05/02
 *  @author ryo.aso
 */
export const pushCourse = (code) => ({
  type: PUSH_COURSE,
  code
});

/**
 *  Action Method
 *  @const {object}
 *  @version 2016/05/02
 *  @author ryo.aso
 */
export const pushPeriod = (id) => ({
  type: PUSH_PERIOD,
  id
});

/**
 *  Action Method
 *  @const {object}
 *  @version 2016/05/02
 *  @author ryo.aso
 */
export const validate = (inputs) => {
  // エラーメッセージ
  const messages = {
    // 教室
    place: (validator.isLength(inputs['place'].trim(), {min: 1, max: 255})) ? null : '選択してください',
    // 保護者の氏名
    parent_name: (validator.isLength(inputs['parent_name'].trim(), {min: 2, max: 255})) ? null : '入力してください',
    // 電話番号
    tel: (validator.isLength(inputs['tel'].trim().replace(/-/g, ''), {min: 10, max: 11})) ? null : '入力してください',
    // メールアドレス
    mail: (validator.isEmail(inputs['mail'].trim())) ? null : '入力してください',
    // 子供の氏名
    child_name: (validator.isLength(inputs['child_name'], {min: 2, max: 255})) ? null : '入力してください',
    // 子供のかな
    child_kana: (validator.isLength(inputs['child_kana'].trim(), {min: 2, max: 255})) ? null : '入力してください',
    // 学年
    grade: (validator.isLength(inputs['grade'], {min: 5, max: 5})) ? null : '入力してください',
    // プライバシーポリシー、利用規約
    check: (validator.contains(inputs['check'], 'consent')) ? null : '同意してください',
  };

  // かなのひらがなバリデート
  if (!messages.child_kana) messages.child_kana = (inputs['child_kana'].match(/^[\u3040-\u309f]+$/)) ? null : 'ひらがなで入力してください';

  // コース
  if (!messages.place) messages.course = (validator.isLength(inputs['course'].trim(), {min: 1, max: 255})) ? null : '選択してください';

  // 日時
  if(!messages.course) messages.period = (validator.isInt(String(inputs['event_id']))) ? null : '選択してください';


  const valid = Object.keys(messages).every( (key) => ( messages[key] === null ) );

  return {
    type: VALIDATE,
    params: { messages, valid },
  };
};
