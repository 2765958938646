// import modules.
import React from 'react';

export default class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props
    };
  }
  
  render() {
    const video = this.state.video;
    const id = this.state.id;
    const item = document.getElementById(id);
    const ITEM_RECT = item.getBoundingClientRect();

    // Safari ブラウザを検出
    const isSafari = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');

    // 自動再生（Safari はスクロールによる自動再生がブロックされるので、自動再生しない）
    if (!isSafari) {
      window.addEventListener(
        'load',
        () => {
          const video_item = document.getElementById(`${id}_video`);
          window.addEventListener(
            'scroll',
            () => {
              if (ITEM_RECT.top - window.innerHeight < window.pageYOffset && 0 < ITEM_RECT.bottom) {
                video_item.play();
              } else if (ITEM_RECT.bottom < window.pageYOffset && ITEM_RECT.bottom < 0) {
                video_item.play();
              }
            },
            false
          );
        },
        false
      );
    }
  
    // スクロールによる自動再生の可否で video タグの動作を切り替える
    const videoExtraProps = isSafari ? { autoPlay: true } : { };

    return (
      <div className='c-videoAutoplay'>
        <video
          src={video}
          ref='video'
          id={`${id}_video`}
          muted
          playsInline
          loop
          {...videoExtraProps}
          className='c-videoAutoplay-video'
        />
      </div>
    );
  }
}
