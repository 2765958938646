/**
 *  Method Name
 *  @const {object}
 */
export const INITIALIZE_ALERTS = 'INITIALIZE_ALERTS';

/**
 *  Method Name
 *  @const {object}
 */
export const DROP_ALERT = 'DROP_ALERT';

/**
 *  Action Method
 *  @const {object}
 *  @version 2016/05/02
 *  @author ryo.aso
 */
export const initializeAlerts = (alerts) => {
  return {
    type: INITIALIZE_ALERTS,
    alerts
  };
};

/**
 *  Action Method
 *  @const {object}
 *  @version 2016/05/02
 *  @author ryo.aso
 */
export const dropAlert = (alerts) => {
  return {
    type: DROP_ALERT
  };
};
