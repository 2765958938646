import { combineReducers } from 'redux'
import { INCREMENT } from './action.es6'

const increment = (state = {
  fuga: 1
}, action) => {

  switch (action.type) {
    case INCREMENT:
      return { fuga: state.fuga + 1 }
    break;

    default:
      return state
  }
};

const rootReducer = combineReducers({
  increment
});

export default rootReducer;
