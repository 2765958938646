// import modules.
import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

// import view component.
import Container from './container.es6';
// import reducer.
import reducer from './reducer.es6';
// import action
import { initializeAlerts } from './action.es6';

/**
 * Register Middleware
 * @const {object}
 */
const middleware = [ thunk ];

/**
 * Create Store
 * @const {object}
 */
const store = createStore(reducer, applyMiddleware(...middleware));

/**
 *  Panels Class
 *  @version 2016/05/02
 *  @author ryo.aso
 */
export default class Sample extends React.Component {
  /**
   *  初期描画直後に一度だけ実行
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  componentWillMount() {
    store.dispatch(initializeAlerts(this.props.alerts));
  }

  /**
   *  表示処理
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  render () {
    return (
      <Provider store={store}>
        <Container />
      </Provider>
    );
  }
}
